































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

function arrayToBase64String(a: Uint8Array): string {
  return btoa(String.fromCharCode(...a));
}

function base64url2base64(input: string): string {
  let output = input
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const pad = output.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    output += new Array(5 - pad).join('=');
  }
  return output;
}

type Purpose = 'Login' | 'ChangePrimaryEmailAddress'
type NextCredentialsAction = false | Array<'captcha' | 'pow' | 'password' | 'totp' | 'public_key_credential' | 'complete'>
type NextEmailLinkAction = false | Array<'captcha' | 'pow' | 'send' | 'confirm' | 'complete'>

interface VuexBindings {
  deviceToken: string | null,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  props: {
    purpose: {
      type: String as () => Purpose,
      required: true,
    },
    user: {
      type: Object as () => ApiResource.Account,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      attempt: {
        id: null as null | string,
        secret: null as null | string,

        credentials: false as NextCredentialsAction,
        email_link: false as NextEmailLinkAction,

        publicKey: null as null | PublicKeyCredentialRequestOptions,
      },

      error: null,
      loading: false,
      ratelimited: false,

      tab: null as null|'credentials'|'email_link',

      issueDeviceToken: !!this.$store.getters['auth/DeviceToken'],

      identifier: '',
      password: '',
      totp: '',
      totp_recovery_secret: '',
      totpRecovery: false,

      emailLinkSecret: null as null | string,

      validation: {
        identifier: null,
        identifier_type: null,
        password: null,
        purpose: null,
        secret: null,
        totp: null,
        totp_recovery_secret: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      deviceToken: 'auth/DeviceToken',
    }),
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(to): void {
        if (to.ATTEMPT_ID && to.ATTEMPT_SECRET) {
          this.attempt.id = to.ATTEMPT_ID;
          this.attempt.secret = to.ATTEMPT_SECRET;
        }
      },
    },
    '$route.params.EMAIL_LINK_SECRET': {
      immediate: true,
      handler(to): void {
        if (!to) return;
        this.tab = 'email_link';
        this.emailLinkSecret = to;
        this.attempt.email_link = ['confirm'];
        // this.attemptEmailLinkSecret();
      },
    },
    'attempt.credentials': function authenticated(to: NextCredentialsAction): void {
      if (!to) return;
      // Resolve when complete
      if (to.includes('complete')) this.authenticated();
      // Request public key credential when only next possible action
      if (to.includes('public_key_credential') && to.length === 1) this.attemptPublicKeyCredentialAssertion();
    },
    // 'attempt.email_link': function authenticated(to, from) {
    //   if (to.includes('complete')) this.authenticated();
    // },
    user: {
      immediate: true,
      handler(to, from): void {
        if (to && from === undefined) {
          this.identifier = to.email;
          this.startAttempt();
        }
      },
    },
  },
  methods: {
    handleAttempt(attempt: any): void {
      console.debug('[Attempt]', attempt);

      if (attempt.id) this.attempt.id = attempt.id;
      if (attempt.secret) this.attempt.secret = attempt.secret;
      // eslint-disable-next-line max-len
      if (attempt.public_key_credential_request_options) this.attempt.publicKey = this.makePublicKeyRequestOptions(attempt.public_key_credential_request_options);

      this.attempt.credentials = attempt.next.credentials;
      this.attempt.email_link = attempt.next.email_link;

      if (!this.tab) {
        if (this.attempt.credentials) this.tab = 'credentials';
        else if (this.attempt.email_link) this.tab = 'email_link';
      }
    },
    makePublicKeyRequestOptions(options: any): PublicKeyCredentialRequestOptions {
      const publicKey = options;

      publicKey.challenge = Uint8Array.from(
        window.atob(base64url2base64(publicKey.challenge)),
        (c) => c.charCodeAt(0),
      );

      if (publicKey.allowCredentials) {
        publicKey.allowCredentials = publicKey.allowCredentials.map((data: any) => {
          // eslint-disable-next-line no-param-reassign
          data.id = Uint8Array.from(window.atob(base64url2base64(data.id)), (c) => c.charCodeAt(0));
          return data;
        });
      }

      return publicKey;
    },
    async startAttempt(): Promise<void> {
      this.error = null;
      this.validation.identifier = null;

      if (!this.identifier) {
        this.setValidationErrors({
          identifier: 'notOptional',
        });
        return;
      }

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/StartAttempt', {
        form: {
          identifier: this.identifier,
          identifier_type: 'email',
          device_token: this.deviceToken,
          purpose: this.purpose,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      if (!attempt) return;

      this.limitRate();

      this.handleAttempt(attempt);
    },
    async attemptPassword(): Promise<void> {
      this.error = null;
      this.validation.password = null;

      if (!this.password) {
        this.setValidationErrors({
          password: 'notOptional',
        });
        return;
      }

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/VerifyPassword', {
        form: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
          password: this.password,
        },
      }).catch((e) => { this.error = e; });

      this.limitRate();

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    async attemptTotp(): Promise<void> {
      this.error = null;
      this.validation.totp = null;

      if (!this.totp) {
        this.setValidationErrors({
          totp: 'notOptional',
        });
        return;
      }
      if (this.totp.length !== 6) {
        this.setValidationErrors({
          totp: 'length',
        });
        return;
      }

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/VerifyTotp', {
        form: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
          totp: this.totp,
        },
      }).catch((e) => { this.error = e; });

      this.limitRate();

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    async attemptTotpRecoverySecret(): Promise<void> {
      this.error = null;
      this.validation.totp_recovery_secret = null;

      if (!this.totp_recovery_secret) {
        this.setValidationErrors({
          totp_recovery_secret: 'notOptional',
        });
        return;
      }
      if (this.totp_recovery_secret.length !== 24) {
        this.setValidationErrors({
          totp_recovery_secret: 'length',
        });
        return;
      }

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/VerifyTotpRecoverySecret', {
        form: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
          totp_recovery_secret: this.totp_recovery_secret,
        },
      }).catch((e) => { this.error = e; });

      this.limitRate();

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    async attemptPublicKeyCredentialAssertion(): Promise<void> {
      this.error = null;

      const { publicKey } = this.attempt;

      if (!publicKey) return;

      this.loading = true;

      const credential: any = await navigator.credentials.get({ publicKey })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });

      if (!credential) return;

      const publicKeyCredential = {
        id: credential.id,
        type: credential.type,
        rawId: arrayToBase64String(new Uint8Array(credential.rawId)),
        response: {
          authenticatorData: arrayToBase64String(
            new Uint8Array(credential.response.authenticatorData),
          ),
          clientDataJSON: arrayToBase64String(new Uint8Array(credential.response.clientDataJSON)),
          signature: arrayToBase64String(new Uint8Array(credential.response.signature)),
          userHandle: credential.response.userHandle ? arrayToBase64String(
            new Uint8Array(credential.response.userHandle),
          ) : null,
        },
      };

      const attempt = await this.$store.dispatch('auth/VerifyPublicKeyCredential', {
        query: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
        },
        form: publicKeyCredential,
      }).catch((e) => { this.error = e; });

      this.limitRate();

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    async sendEmailLink(): Promise<void> {
      this.error = null;

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/SendEmailLink', {
        form: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
        },
      }).catch((e) => { this.error = e; });

      this.limitRate(1000 * 10); // 10 seconds

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    async attemptEmailLinkSecret(): Promise<void> {
      this.error = null;
      this.validation.secret = null;

      if (!this.emailLinkSecret) {
        this.setValidationErrors({
          secret: 'notOptional',
        });
        return;
      }

      this.loading = true;

      const attempt = await this.$store.dispatch('auth/VerifyEmailLinkSecret', {
        form: {
          attempt_id: this.attempt.id,
          attempt_secret: this.attempt.secret,
          secret: this.emailLinkSecret,
        },
      }).catch((e) => { this.error = e; });

      this.limitRate();

      this.loading = false;

      if (!attempt) return;

      this.handleAttempt(attempt);
    },
    authenticated(): void {
      this.loading = true;

      if (this.issueDeviceToken) {
        // Only needs dispatch, can be done async
        this.$store.dispatch('auth/IssueDeviceToken', {
          form: {
            attempt_id: this.attempt.id,
            attempt_secret: this.attempt.secret,
          },
        }).catch((e) => { this.error = e; });
      }

      this.$emit('authenticated', {
        id: this.attempt.id,
        secret: this.attempt.secret,
      });
    },
    limitRate(ms = 1000): void {
      this.ratelimited = true;
      window.setTimeout(() => {
        this.ratelimited = false;
      }, ms);
    },
    setValidationErrors(v: any): void {
      this.validation = v;
    },
  },
});
